.web-notise-white-background {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    padding-left: 25px;
    padding-right: 25px;
    background: var(--main-color-background-box);
    box-shadow: 5px 10px 10px 0px #0000001a;
    overflow: hidden;
}