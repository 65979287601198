.main-site {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 665px;
    background: #F2F0F1;
    gap: 10%;
}

.main-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 100%;
}

.main-first-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: 45%;
    height: 100%;
}

.main-first-block h1 {
    width: 100%;
    font-family: "Fredericka the Great", serif;
    font-size: 35px;
    font-weight: 600;
    margin-bottom: 40px;
    letter-spacing: 10px;
}

.first-block-description {
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 30px;
}

.btn-main-first-block {
    width: 210px;
    height: 52px;
    border-radius: 62px;
    background: #000;
    box-shadow: 1px 1px 5px 0px #000;
    color: #fff;
    font-size: 14px;
    transition: 0.3s;
    margin-bottom: 50px;
}

.btn-main-first-block:hover {
    background: #000;
    box-shadow: 1px 1px 10px 0px #000;
    font-size: 16px;
    transition: 0.3s;
}

.btn-main-first-block:active {
    background: #5a5a5a;
}

.main-second-statistics {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
}

.statistics {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    width: 30%;
    height: auto;
    gap: 8%;
}

.statistics-number {
    font-size: 40px;
    font-weight: 600;
}

.statistics-name {
    font-size: 16px;
    font-weight: 400;
}

.main-second-block {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: start;
    width: 45%;
    height: 100%;
}

.main-second-block-img {
    width: 100%;
    height: auto;
    margin-bottom: -1px;
}

