.main-text-powerpoint-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
}

.main-text-powerpoint-container h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
    font-size: 30px;
    font-weight: 700;
    margin: 50px 0;
}

.main-text-powerpoint-dropzone-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
    font-size: 18px;
    text-align: center;
    border: 2px dashed #ccc;
    border-radius: 10px;
    color: #888;
    margin-bottom: 20px;
}

.main-text-powerpoint-dropzone {
    opacity: 0;
    z-index: 1;
    position: relative;
    text-align: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.dropzone-container-p {
    z-index: 0;
    position: absolute;
    top: 25%;
    text-align: center;
    color: #888;
    cursor: pointer;
}

.main-text-powerpoint-button {
    display: block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.dropzone-container-name-file {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 50%;
    padding-bottom: 15px;
}

.dropzone-container-p-name-file {
    width: 45%;
    cursor: pointer;
    font-size: 14px;
    color: #888;
}

.dropzone-container-p-name-file-count {
    z-index: 1;
    position: absolute;
    top: 50%;
    width: 100%;
    cursor: pointer;
    font-size: 14px;
    color: #888;
}

.error-message {
    color: red;
    font-size: 14px;
    margin-top: 10px;
}

.error-text {
    color: red;
    font-weight: bold;
}

.dropzone-container-name-file {
    list-style-type: none;
}

.main-text-powerpoint-button:disabled {
    background-color: #ccc;
}