.main-pick-up {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 136px;
    margin: 32px auto;
    color: #fff;
}

.box-pick-up {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 45%;
    height: 136px;
    border-radius: 10px;
    padding: 24px 48px;
    color: #000;
    background: #fff;
}

.swipe-pick-up {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 10px;
    background: #3563E9;
}

.swipe-pick-up:hover {
    background: #5b76c5;
}

.swipe-pick-up:active {
    background: #1f3d97;
}

.select-pick-up {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.radio-box {
    width: 100%;
    margin-bottom: 20px;
}

input[type="radio"] {
    margin-right: 10px;
}

.label-select {
    font-size: 16px;
    font-weight: 800;
    color: #000;
    margin-bottom: 10px;
    text-align: center;
}

.select-pick-up select {
    width: 100%;
    margin-right: 20px;
}

.select-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 40px;
    margin-bottom: 20px;
}

.divider {
    width: 1px;
    height: 50px;
    background: #8d8d8d;
    margin: 0 10px;
}