.fade-in-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
  
    opacity: 0;
    transform: translateX(-40px);
    transition: opacity 1s ease-out, transform 1s ease-out;
  }
  
  .fade-in-right.show {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
  
    opacity: 1;
    transform: translateX(0);
  }