.toggle-switch {
    display: flex;
    align-items: center;
  }
  
.toggle-switch-label {
    margin-right: 10px;
    font-weight: 500;
}
  
.switch {
    width: 50px;
    height: 25px;
    background-color: #ccc;
    border-radius: 25px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.switch-on {
    background-color: #fff;
    box-shadow: inset 0px 0px 2px 0px #4d4d4d;
}

.switch-off {
    background-color: #ccc;
}

.switch-handle {
    width: 23px;
    height: 23px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    left: 1px;
    transition: left 0.3s ease;
}

.switch-on .switch-handle {
    left: 26px;
    background-color: #494949;
}