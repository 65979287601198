.main-text-check-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    color: var(--main-color-font);
}

.main-text-check-container-text-h1 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 5rem;
}

.words-change-dropzone-imput {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 300px;
    gap: 25px;
}

.words-change-dropzone-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 100%;
    font-size: 18px;
    text-align: center;
    border: 2px dashed #ccc;
    border-radius: 10px;
}

.words-change-dropzone {
    opacity: 0;
    z-index: 1;
    position: relative;
    text-align: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.words-change-dropzone-p {
    z-index: 0;
    position: absolute;
    width: 90%;
    top: 25%;
    font-size: clamp(10px, 3vw, 14px);
    text-align: center;
    cursor: pointer;
}

.words-change-dropzone-textarea {
    width: 30%;
    height: 100%;
    resize: none;
    font-size: clamp(10px, 3vw, 14px);
    border: 2px solid #ccc;
    border-radius: 10px;
    padding: 10px;
}

.words-change-dropzone-textarea::placeholder {
    color: var(--main-color-font);
    font-size: clamp(10px, 3vw, 14px);
}

.words-change-dropzone-sample{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10%;
    width: 30%;
    height: 100%;
    border: 2px solid #ccc;
    border-radius: 10px;
    padding: 10px;
}

.words-change-sample-new-template {
    width: 90%;
    height: 20%;
    background: var(--leftbar-color-background);
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 5px;
    text-align: center;
    box-shadow: 1px 1px 2px 2px #dddddd;
    margin-top: 20px;
}

.words-change-sample-new-template::placeholder {
    color: var(--main-color-font);
}

.words-change-sample-new-template-button {
    display: block;
    padding: 10px 20px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 5%;
}

.words-change-sample-new-template-button:hover {
    background-color: #186fcc;
}

.words-change-sample-new-template-button:active {
    background-color: #6c90b8;
}

.words-change-sample-new-template-select {
    display: block;
    padding: 10px 20px;
    background-color: #8654be;
    color: #fff;
    border: none;
    border-radius: 5px;
}

.words-change-sample-new-template-select option {
    background-color: #8654be;
    font-size: clamp(10px, 3vw, 12px);
    padding: 5px;
    border-radius: 2px;
}

.text-check-button-process {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #2b9c3e;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 5% 0;
}

.text-check-button-process:hover {
    background-color: #1f702d;
}

.text-check-button-process:active {
    background-color: #4e9c5b;
}

.words-change-result {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    font-size: clamp(10px, 3vw, 16px);
    font-weight: 600;
}

.words-change-result-h2 {
    margin-bottom: 30px;
}

.words-change-result-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: auto;
    padding: 20px;
    box-shadow: 1px 1px 2px 2px #dddddd;
}