.routers {
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	height: auto;
	margin-top: 30px;
}

.links-router {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: auto;
}

.link {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 80%;
	height: 60px;
	background: #ffffff;
	color: #000;
	border-radius: 12px;
	margin: 10px 0;
	box-shadow: 0px 20px 27px 0px #0000001a;
}

.link_reset {
	background: "#ffffff";
	border: "solid 0px #fff";
	box-shadow: "0px 0px 0px 0px #0000001a"
}

.link:hover {
	background: #aa65a0a9;
	color: #fff;
}

.link_active {
	color: #fff;
	background: #aa65a0;
	box-shadow: 1px 1px 3px 0px #d268e783;
}

.img-box {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 48px;
}

.link-img {
	position: relative;
	left: 78%;
	width: 48px;
	height: 48px;
	margin-top: 10px;
}

.link-img-active {
	position: relative;
	left: -21%;
	width: 48px;
	height: 48px;
	margin-top: 10px;
	opacity: 0;
}

.link-text {
	width: 70%;
	font-size: 12px;
	font-weight: 600;
	margin-left: 20px;
}