.new-arrivals-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 750px;
}

.new-arrivals-h2 {
    font-size: 48px;
    text-align: center;
    font-weight: bold;
    margin: 72px 0;
}

.new-arrivals-items-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 500px;
    background: #69658d;
}