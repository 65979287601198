.sites-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.sites-box-description {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 350px;
    padding: 20px;
    margin-bottom: 50px;
    overflow: hidden;
}

.sites-box-description p {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 60%;
    height: 100%;
    font-size: 20px;
    font-weight: 700;
    color: var(--main-color-font);
    padding: 50px;
    border-radius: 14px;
    background: var(--main-color-background-box);
    box-shadow: 5px 10px 10px 0px #0000001a;
}

.img-sites-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 30%;
    max-width: 300px;
    padding: 10px;
    border-radius: 14px;
    box-shadow: 0px 0px 15px 5px #0000001a;
}

.img-sites-box img {
    width: 100%;
    border-radius: 8px;
    box-shadow: 5px 10px 10px 0px #0000001a;
}

.sites-box-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 30px;
}

.dev-sites-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 280px;
    height: 300px;
    padding: 20px;
    padding-top: 0;
    margin: 30px 20px;
    border-radius: 10px;
    border: solid 2px #fff;
    box-shadow: 0px 0px 15px 5px #0000001a;
    overflow: hidden;
    background:#967cb791;
    transition: .5s;
}

.dev-sites-block:hover {
    box-shadow: 8px 13px 8px 0px #0000001a;
    transform: scale(1.05);
    transition: .5s;
}

.dev-sites-block:hover .dev-site-img {
    transform: scale(1.05);
    transition: .5s;
}

.dev-img-box {
    width: 200px;
    height: 200px;
}

.dev-site-img {
    width: 100%;
    border: solid 1px #fff;
    border-radius: 15px;
    transition: .5s;
}

.site-name-text {
    width: 100%;
    height: 30px;
    padding-top: 30px;
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    text-transform: uppercase;
}