/* Для планшетов */
@media (max-width: 1024px) {

	.app {
		width: 99%;
	}

	.header {
		display: flex;
		flex-direction: column;
		height: auto;
	}

	.burger-menu-box {
		display: flex;
	}
	
	.right-side {
		width: 100%;
		margin: 0 auto;
	}
	
	.left-sidebar {
		display: none;
	}

	.greetings {
		margin-bottom: 30px;
	}

	.news-web-dev {
		width: 100%;
		height: 100px;
		margin-bottom: 20px;
	}

	.second-block {
		width: 100%;
		height: 100px;
		margin-bottom: 20px;
	}

	.third-block {
		width: 100%;
		height: 100px;
		margin-bottom: 20px;
	}

	.about-expression-box {
		padding: 30px;
	}

	.about-expression-img {
		width: 300px;
		height: 300px;
	}

	.about-expression {
		font-size: 16px;
	}

	.about-expression-box video {
		width: 25vw;
		margin-bottom: -60px;
	}

	.welcom {
		font-size: 24px;
		margin: 20px 0;
	}

	.why-me, .my-philosophy-name, .my-skills-name_block {
		font-size: 16px;
	}

	.why-me-description, .my-philosophy-description, .my-skills-description {
		font-size: 14px;
	}

	.my-skills-img_block {
		width: 60px;
		height: 60px;
		margin: 0 20px;
	}

	.about-me {
		margin: 0;
	}

	.about-me-description video {
		margin-top: -30px;
	}

	.my-philosophy-random-code {
		width: 50%;
	}

	.my-skills-description_block  {
		padding: 30px;
	}

	.contact-with-me {
		margin-top: 80px;
	}
}

