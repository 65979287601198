.web-notise-app_block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 350px;
}

.web-notise-text-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 300px;
}

.close-text {
    opacity: 0;
    margin-top: -250px;
    transition: 1.5s;
}

.open-text {
    opacity: 1;
    margin-top: 0;
    transition: 1.5s;
}

.web-notise-text-box_h1 {
    width: 100%;
    height: 50px;
    font-size: 20px;
    margin-bottom: 30px;
}

.web-notise-text-box_text {
    width: 100%;
    height: 120px;
    text-align: center;
    font-size: 16px;
}

.web-notise-text-box_line {
    width: 80%;
    margin-top: 30px;
    border-bottom: solid 1px #000;
}

.web-notise-text-box_tag {
    width: 100%;
    height: 50px;
    text-align: center;
    margin-top: 30px;
    font-size: 14px;
}

.web-notise-img-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 250px;
}

.close-img-box {
    opacity: 0;
    margin-right: -100px;
    transition: 1.5s;
}

.open-img-box {
    opacity: 1;
    margin-right: 0;
    transition: 1.5s;
}

.web-notise-img-box_img {
    width: 80%;
    margin: 0 auto;
    align-content: center;
    border-radius: 15px;
}

.web-notise-box h1 {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-size: 24px;
}

.web-notise-app {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 350px;
    margin-top: 50px;
    border-radius: 15px;
}