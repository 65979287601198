.container-notise {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
}

.button-slide {
    width: 30px;
    height: 100%;
    margin: 0 30px;
    scale: 1;
    transition: .5s;
}

.button-slide:hover {
    scale: 1.2;
    transition: .5s;
}

.slide-next-arraw {
    rotate: 180deg;
}

.icon-edite {
    position: absolute;
    top: 10px; 
    right: -40px;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    border: 2px solid #ffffff;
    box-shadow: 2px 5px 5px 2px #aaa;
}