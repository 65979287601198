.fileUploadForm-music-box {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: #3a3a3a8a;
}

.fileUploadForm-music-form {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 50vw;
    height: 30vw;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 20px 27px 0px #0000001a;
}

.icon-close-upload-music-box {
    position: absolute;
}

.fileUploadForm-name-view {
    margin: 20px 0;
}

.fileUploadForm-button {
    cursor: pointer;
    padding: 10px;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    box-shadow: 1px 1px 5px 0px #00000070;
}


.fileUploadForm-button:hover {
    box-shadow: 2px 2px 5px 0px #000000d0;
}

.fileUploadForm-button:active {
    box-shadow: 2px 2px 8px 0px #000000;
    background-color: #799acc;
}

.button-upload {
    background-color: #3b66a7;
}

.button-save {
    background-color: #578fe2;
}