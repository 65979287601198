.left-sidebar-burger {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100vw + 0px);
    height: 100vh;
    overflow-y: hidden;
    overflow-x: hidden;

    background: #6d5995;
    z-index: 1000;
}

.app-header-burger {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 30vh;
}

.routers-burger {
    width: 100%;
    height: 60vh;
}

.burger-open {
    display: flex;
}

.logo-menu video {
    width: 150px;
}