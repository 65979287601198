.form-delete-bg {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: #00000080;
}

.form-delete-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 30vw;
    height: 30vh;
    padding: 50px;
    border-radius: 10px;
    background: #ffffff;
}

.form-delete-box-label {
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}

.button-delete-notise {
    width: 35%;
    height: 25%;
    padding: 10px;
    color: #fff;
    text-align: center;
    border-radius: 10px;
    box-shadow: 1px 1px 5px 0px #00000070;
    transition: 0.3s;
}

.button-delete-notise:hover {
    box-shadow: 3px 3px 8px 0px #000000b2;
    transition: 0.3s;
}

.button-delete-notise:active {
    box-shadow: 3px 3px 8px 0px #000000e8;
    transition: 0.3s;
}

.button_start {
    background: #d84d64;
}

.button_cancel {
    background: #799acc;
}