.header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 96px;
    background: #fff;
}

.header-site-shop-co {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 48px;
    background: #fff;
    gap: 3%;
}

.site-shop-co-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
    height: 100%;
}

.site-shop-co-logo a {
    font-size: 32px;
    font-weight: bold;
    color: #000;
}

.site-shop-co-logo a:hover {
    color: #000;
}

.site-shop-co-logo a:active {
    color: #000;
}

.navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 30%;
    height: 100%;
}

.navigation ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
}

.navigation li a {
    color: #000;
    text-decoration: none;
}

.navigation li a:hover {
    border-bottom: 2px solid #e94de1;
}

.search-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
    height: 100%;
    border-radius: 62px;
    background: #F0F0F0;
}

.account-basket {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5%;
    height: 100%;
}

.icon-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 15%;
}

.icon-block-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 30px;
    height: 100%;
}

.icon-block-img {
    width: 100%;
    height: auto;
}