.main-banners {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 360px;
    margin: 50px auto;
    margin-bottom: 20px;
    color: #fff;
}

.banners-main {
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 49%;
    height: 360px;
    border-radius: 10px;
    overflow: hidden;
}

.banner-1 {
    background: #54A6FF;
}

.banner-2 {
    background: #3563E9;
}

.banner-box-img {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
}

.banner-box-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 50%;
    height: 100%;
    padding: 24px;
}

.banner-text-name {
    font-size: 32px;
    color: #fff;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: -3%;
}

.banner-text-description {
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -2%;
    margin-top: 16px;
}

.btn-main-banners {
    width: 120px;
    height: 44px;
    border-radius: 4px;
    box-shadow: 1px 1px 5px 0px #00000079;
    margin-top: 20px;
}

.btn-main-banner-1 {
    background: #3563E9;
}

.btn-main-banner-2 {
    background: #54A6FF;
}

.btn-main-banner-1:hover {
    background: #294ebb;
}

.btn-main-banner-2:hover {
    background: #4689cf;
}

.btn-main-banner-1:active {
    background: #1c388a;
}

.btn-main-banner-2:active {
    background: #29609b;
}