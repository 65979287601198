.online-notise-background {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: #fff;
    overflow: hidden;
}

.online-notise-background video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: auto;
    z-index: 0;
}

.online-web-notise-app_block {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 75%;
    height: 500px;
    margin: 0 auto;
    margin-bottom: 300px;
    padding: 50px;
    border-radius: 25px;
    overflow: hidden;
    box-shadow: 0px 0px 5px 0px #00000050;
    font-family: "Open Sans", sans-serif;
    background: #fff;
    z-index: 100;
}

.online-web-notise-text-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 100%;
}

.close-text {
    opacity: 0;
    margin-top: -250px;
    transition: 2s;
}

.open-text {
    opacity: 1;
    margin-top: 0;
    transition: 2s;
}

.online-web-notise-text-box_h1 {
    width: 100%;
    height: 50px;
    font-size: 20px;
    text-align: center;
    margin-bottom: 30px;
}

.online-web-notise-text-box_text {
    width: 100%;
    height: 120px;
    text-align: center;
    font-size: 16px;
}

.online-web-notise-text-box_line {
    width: 80%;
    margin-top: 30px;
    border-bottom: solid 1px #000;
}

.online-web-notise-text-box_tag {
    width: 100%;
    height: 50px;
    text-align: center;
    margin-top: 30px;
    font-size: 14px;
}

.web-notise-img-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: auto;
}

.close-img-box {
    opacity: 0;
    margin-right: -100px;
    transition: 2s;
}

.open-img-box {
    opacity: 1;
    margin-right: 0;
    transition: 2s;
}

.online-web-notise-img-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 30%;
    height: auto;
}

.online-web-notise-img-box_img {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    align-content: center;
    border-radius: 15px;
}

.online-web-notise-box h1 {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-size: 24px;
}