body {
    margin: 0;
    padding: 0;
}

.main-container-site {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 auto;
    width: 100%;
    max-width: 1440px;
    height: auto;
    font-family: "Teachers", serif;
    font-optical-sizing: auto;
    font-style: normal;
    background: #F6F7F9;
}