.aplications-window {
	margin-top: 50px;
	width: 90%;
	height: auto;
	font-family: "IBM Plex Sans", sans-serif;
	margin-bottom: 50px;
}

.about-me {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: auto;
	border-radius: 15px;
	padding: 20px;
	margin: 50px 0;
}

.greetings {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 300px;
	margin-bottom: 100px;
}

.about-expression-box {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	width: 50%;
	height: 350px;
	font-size: 24px;
	font-weight: 700;
	text-transform: uppercase;
	background: var(--main-color-background-box);
	padding: 50px;
	border-radius: 14px;
	box-shadow: 0px 20px 27px 0px #0000001a;
	overflow: hidden;
}

.about-expression {
	width: 100%;
	font-size: 24px;
	font-weight: 700;
	text-transform: uppercase;
}

.about-expression-box video {
	width: 15vw;
    margin-top: -2%;
}

.about-expression-img {
	position: relative;
	width: 400px;
	height: 400px;
	border-radius: 14px;
	box-shadow: 0px 0px 10px 1px #00000088;
	overflow: hidden;
}

.image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}


/* Animations */
.blink-effect {
	position: absolute;
	top: 0;
	left: -150%;
	width: 30%;
	height: 100%;
	background: linear-gradient(120deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.767) 50%, rgba(255, 255, 255, 0) 100%);
	transform: skewX(-25deg);
	animation: blink 3s infinite;
	opacity: .35;
	filter: blur(50px);
}

@keyframes blink {
	0% {
		left: -100%;
	}
	50% {
		left: 200%;
	}
	100% {
		left: 500%;
	}
}
/* Animations */

.welcom {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50%;
	height: 100px;
	font-size: 30px;
	font-weight: 700;
	margin: 50px 0;
}

.about-me-description {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: auto;
	font-size: 16px;
	font-weight: 400;
	background: var(--main-color-background-box);
	margin-bottom: 50px;
	padding: 50px;
	border-radius: 14px;
	box-shadow: 0px 20px 27px 0px #0000001a;
}

.why-me {
	font-size: 18px;
	margin: 20px 0;
	font-weight: 700;
	text-transform: uppercase;
}

.why-me-description{
	font-size: 16px;
	margin: 20px 0;
	font-weight: 400;
}

.video-conveyor {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: -50px;
	margin-bottom: -50px;
}

.video-conveyor video {
	width: 60%;
	-webkit-filter: saturate(1.3)
}

.my-philosophy {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	font-size: 16px;
	font-weight: 400;
	background: var(--main-color-background-box);
	margin-bottom: 50px;
	padding: 50px;
	border-radius: 14px;
	box-shadow: 0px 20px 27px 0px #0000001a;
}

.my-philosophy-name {
	font-size: 18px;
	margin: 20px 0;
	font-weight: 700;
	text-transform: uppercase;
}

.my-philosophy-description {
	font-size: 16px;
	margin: 20px 0;
	font-weight: 400;
}

.my-philosophy-random-code {
	width: 35%;
	height: 25px;
	margin: 0 auto;
	border-radius: 8px;
	box-shadow: 0px 10px 15px 0px #00000060;
	border: solid 1px #fff;
	margin-top: 20px;
	color: var(--my-philosophy-color);
	font-weight: 600;

	background: #f4a73368;
}

.my-philosophy-random-code p {
	width: 50%;
	text-align: center;
	margin: 0 auto;
}

.video-conveyor-lowpoly {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 200px;
}

.video-conveyor-lowpoly video {
	width: 60%;
	-webkit-filter: saturate(1.3)
}

.my-skills {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: auto;
}

.my-skills-description_block {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 600px;
	font-size: 16px;
	font-weight: 400;
	background: var(--main-color-background-box);
	margin: 20px 0;
	padding: 50px;
	border-radius: 14px;
	box-shadow: 0px 20px 27px 0px #0000001a;
}

.my-skills-name_block {
	font-size: 18px;
	margin: 20px 0;
	font-weight: 700;
	text-transform: uppercase;
}

.my-skills-description_block {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: auto;
}

.reverse {
	flex-direction: row-reverse;
}

.my-skills-img_block {
	position: relative;
	width: 50px;
	height: 50px;
	border-radius: 8px;
	box-shadow: 0px 0px 5px 1px #00000058;
	overflow: hidden;
}

.my-skills-img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.my-skills-description {
	width: 90%;
	font-size: 16px;
	margin: 20px 0;
	font-weight: 400;
}

.contact-with-me {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 50%;
	height: 80px;
	margin-top: 50px;
}

.link-social {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 80px;
	height: 100%;
	transition: .5s;
}

.link-social:hover {
	transform: scale(1.1);
	transition: .5s;
}

.link-social:hover .social-name {
	font-weight: 500;
	transition: .5s;
}

.link-social:hover .social-icon {
	transition: 1s;
}


.social-icon {
	width: 50px;
	height: 50px;
	border-radius: 50px;
	border: solid 1px #fff;
	overflow: hidden;
	box-shadow: 3px 3px 5px 1px #00000058;
}

.social-icon-img {
	width: 100%;
	height: auto;
}

.social-name {
	color: var(--social-name-color);
	text-transform: uppercase;
}

.carousel-my-work-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 100%;
}

.carousel-my-work {
	width: 100%;
	height: auto;
	opacity: 1;
	transition: 1s;
}

.next-work {
	width: 100%;
	height: auto;
	opacity: 0;
	transition: 1s;
}

.carousel-my-work-name {
	position: absolute;
	width: 100%;
	text-align: center;
	margin: 0 auto;
	bottom: 0px;
	font-weight: 600;
	transition: 1s;
	padding: 20px 0;
	color: #fff;
	background: #aa65a0;
}