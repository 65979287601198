.web-notise-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
}

.web-notise-admin {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: auto;
    margin: 50px 0px;
}

.web-notise-box h1 {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-size: 24px;
}

.web-notise-app {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 350px;
    margin-top: 50px;
    border-radius: 15px;
}