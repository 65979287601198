:root {
	--main-color-background: #F8F9FA;
	--main-color-font: #3a3a3a;
	--leftbar-color-background: #efefef;
	--main-color-background-box: #fff;
	--my-philosophy-random-color: #818181;
	--social-name-color: #00000066;
	--social-name-color-hover: #0000009e;
}

.MainApp {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: calc(100vw - 0px);
	height: calc(100vh - 0px);
	background: var(--main-color-background);
	color: var(--main-color-font);
	margin: 0 auto;
	overflow: hidden;
	font-family: "Open Sans", sans-serif;
	overflow-x: hidden;
	overflow-y: hidden;
}

.left-sidebar {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 20%;
	height: calc(100vh - 2px);
	border-radius: 15px;
	padding: 20px;
	overflow-x: hidden;
	overflow-y: auto;
	background: var(--leftbar-color-background);
}

.app-header {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: auto;
	margin-top: 20px;
}

.box-logo {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	width: 80%;
	padding: 20px;
	border-radius: 10px;
	background: #68036db1;
	box-shadow: 0px 0px 10px 1px #0000006e;
	background-image: url("../public/assets/logo/001.png");
}

.logo {
	width: 70%;
}

video {
	width: 100%;
}

.right-side {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 80%;
	height: 100%;
	margin-left: 25px;
	overflow-x: hidden;
	overflow-y: auto;
}

.burger-menu-box {
	position: fixed;
	z-index: 1000;
	display: none;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 70px;
    bottom: 0;
    border-radius: 20px 20px 0 0;
    background: #6e4799;
	box-shadow: 0px 0px 2px 2px #fff;
	opacity: .9;
}

.button-burger-menu {
    width: 35px;
    height: 35px;
	margin: 0 auto;
}

.img-burger-arraw__open {
	rotate: 90deg;
	transition: .5s;
}

.img-burger-arraw__close {
	rotate: -90deg;
	transition: .5s;
}
