.games-list-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: 75px;
    overflow-x: auto; 
    white-space: nowrap;
    padding: 5px;
}

.games-list-container::-webkit-scrollbar {
    display: none;
}

.second-block-name-block {
    font-size: 10px;
}

.games-list-month {
    font-size: 14px;
}

.games-list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    gap: 15px;
    padding: 0 2px;
}

.games-list::-webkit-scrollbar {
    display: none;
}

.games-list-item {
    width: auto;
    color: var(--main-color-font);
    text-decoration: none;
}

.item-game-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 120px;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0px 0px 2px 1px #b1b1b1;
    background: #e7e7e770;
}

.item-game-img-block {
    width: 25%;
    height: 30px;
    overflow: hidden;
}

.item-game-image {
    height: auto;
    width: 100%;
    border-radius: 5px;
}

.item-game-component {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 70%;
    font-size: 8px;
}