.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	height: 80px;
	border-radius: 15px;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 10px 15px;
}

.news-web-dev {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 30%;
	height: 80px;
	background: var(--main-color-background-box);
	border-radius: 8px;
	padding: 10px;
	box-shadow: 0px 20px 27px 0px #0000001a;
	align-items: normal;
}

.news-link-close {
	opacity: 0;
	transition: 1s;
}

.news-link-open {
	opacity: 1;
	transition: 1s;
}

.news-detals-name-img {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	height: 80px;
}

.news-detals-img {
	width: 60px;
	height: 60px;
	border-radius: 8px;
	overflow: hidden;
}

.img-news {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.name-news {
	width: 80%;
	font-size: 10px;
	color: var(--main-color-font);
	font-weight: 700;
	margin-left: 10px;
}

.news-detals {
	display: flex;
	flex-direction: row;
	justify-content: end;
	width: 100%;
	height: 10px;
	font-size: 8px;
	color: var(--main-color-font);
	font-weight: 600;
	margin-top: -30px;
}

.second-block {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 40%;
	height: 80px;
	border-radius: 8px;
	padding: 0 10px;
	overflow: hidden;
	background: var(--main-color-background-box);
	box-shadow: 0px 20px 27px 0px #0000001a;
}

.third-block {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 20%;
	height: 80px;
	border-radius: 8px;
	padding: 10px;
	background: var(--main-color-background-box);
	box-shadow: 0px 20px 27px 0px #0000001a;
}