.nav-bar-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 124px;
    margin: 0 auto;
    background: #ffffff;
}

.logo-main {
    flex: 0 0 10%;
    margin-left: 5%;
    font-size: 32px;
    color: #3563E9;
    font-weight: 600;
    letter-spacing: 0.1em;
    cursor: pointer;
}

.search-main {
    /* flex: 0 0 35%; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 30%;
    height: 44px;
    margin-left: 50px;
    border: 1px solid #cecece;
    border-radius: 70px;
}

.magnifying-glass {
    width: 30px;
    height: 24px;
    margin-left: 20px;
}

.magnifying-glass-img, .search-settings-img {
    max-width: 30px;
    height: auto;
}

.search-input {
    flex: 1;
    margin-left: 10px;
}

.settings-search {
    width: 30px;
    height: 24px;
    margin-right: 20px;
    cursor: pointer;
}

.profil-notification {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.profil-box-icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    margin: 0 20px;
    margin-left: 0;
    border: 1px solid #e2e2e2;
    border-radius: 30px;
    cursor: pointer;
}