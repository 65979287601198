/* Для мобильных устройств */
@media (max-width: 480px) {

	.app {
		width: 99%;
	}

	.MainApp {
		height: auto;
	}
	
	.right-side {
		width: 100%;
		height: auto;
		margin: 0 auto;
		margin-bottom: 200px;
	}
	
	.left-sidebar {
		display: none;
	}

	.header {
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		height: 350px;
		margin-bottom: 0;
	}

	.news-web-dev, .second-block, .third-block {
		width: 100%;
		height: 80px;
		margin-bottom: 20px;
	}

	.aplications-window {
		width: 100%;
		margin-top: 10px;
	}

	.sites-box-items {
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 300px;
		margin-bottom: 50px;
	}

	.sites-box-description {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		height: 550px;
	}

	.sites-box-description p {
		width: 100%;
		height: 200px;
		font-size: 16px;
		padding: 10px;
	}

	.img-sites-box {
		width: 70%;
		height: auto;
	}

	.greetings {
		flex-direction: column;
		width: 100%;
		height: 600px;
		margin-bottom: 30px;
	}

	.about-expression-box {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		height: 250px;
		padding: 20px;
	}

	.about-expression-img {
		width: 300px;
		height: 300px;
	}

	.about-expression {
		font-size: 16px;
	}

	.about-expression-box video {
		width: 250px;
		margin-top: 30px;
	}

	.welcom {
		width: 100%;
		font-size: 18px;
		margin: 20px 0;
	}

	.why-me, .my-philosophy-name, .my-skills-name_block {
		font-size: 14px;
	}

	.why-me-description, .my-philosophy-description, .my-skills-description {
		font-size: 12px;
	}

	.my-skills-img_block {
		width: 60px;
		height: 60px;
		margin: 0 20px;
	}

	.about-me {
		margin: 0;
	}

	.about-me-description {
		padding: 20px;
	}

	.about-me-description, .my-philosophy {
		padding: 20px;
	}

	.my-philosophy-name {
		margin: 0;
	}

	.about-me-description video {
		width: 400px;
		height: auto;
		margin-top: 0;
	}

	.my-philosophy-random-code {
		width: 100%;
	}

	.my-skills-description_block  {
		flex-direction: column;
		padding: 30px;
	}

	.my-skills-description {
		width: 100%;
	}

	.contact-with-me {
		width: 100%;
		margin-top: 50px;
	}

	.contact-with-me p {
		font-size: 12px;
	}


	.web-aplications-box-description {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		height: 550px;
	}

	.web-aplications-box-description p {
		width: 100%;
		height: 200px;
		font-size: 16px;
		padding: 10px;
		margin-bottom: 80px;
	}

	.img-web-aplications-box {
		width: 70%;
		height: auto;
		margin-bottom: 150px;
	}

	.web-aplications-box-items {
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 300px;
	}

	.container-notise {
		flex-direction: column;
	}

	.web-notise-text-box {
		height: 200px;
	}

	.web-notise-text-box h1 {
		font-size: 12px;
	}

	.web-notise-text-box_text {
		font-size: 10px;
	}

	.web-notise-text-box_tag {
		font-size: 10px;
	}

	.block-button-admin {
		width: 90vw;
		font-size: 12px;
	}

	.main-text-powerpoint-container {
		width: 90%;
		margin: 0 auto;
	}
}