.block-button-admin {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-bottom: 50px;
}

.button-admin {
    width: 180px;
    height: 80px;
    border-radius: 20px;
    font-weight: 600;
    color: #fff;
    background: #aa65a0;
    border: 1px solid #fff;
    padding: 10px;
    box-shadow: 0px 0px 3px 0px #929292;
    transition: .5s;
}

.button-admin:hover {
    background: #8a4680;
    box-shadow: 5px 5px 5px 2px #aaa;
    transition: .5s;
}

.button-admin:active {
    background: #7e2070;
}